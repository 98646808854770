import Link from 'next/link';
import React, {useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {DASHBOARD_URI} from '../../lib/constants';
import {useAccount} from '../../lib/hooks/accounts';
import Logo from '../../../public/assets/logo.svg';

/**
 *
 */
export default function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <nav className={'top-0 z-40 mx-auto max-w-7xl px-8 xl:px-0'}>
      <div className={'z-40 py-4'}>
        <div className="flex justify-between">
          <div className="flex">
            <div className="flex flex-shrink-0 items-center">
              <Link href={'/'} className={'flex items-center gap-x-3 text-xl font-semibold text-black'}>
                <div className={'relative h-9 w-9'}>
                  <Logo />
                </div>
                <span className={'sr-only'}>Plunk</span>
              </Link>
            </div>
            <div className="hidden items-center sm:ml-6 sm:space-x-7 md:flex ">
              <Link href={'/made-by-humans'} className={`font-medium text-neutral-800`}>
                By humans
              </Link>

              <Link href={'/pricing'} className={`font-medium text-neutral-800`}>
                Pricing
              </Link>

              <Link href={'/blog'} className={`font-medium text-neutral-800`}>
                Blog
              </Link>

              <Link
                href={'https://docs.useplunk.com'}
                target={'_blank'}
                rel={'noreferrer'}
                className={`
                    flex items-center gap-x-1 font-medium text-neutral-800`}
              >
                Docs
                <svg className={'h-4 w-4 text-neutral-600'} fill="none" viewBox="0 0 24 24">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9.25 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H17.25C18.3546 19.25 19.25 18.3546 19.25 17.25V14.75"
                  />
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19.25 9.25V4.75H14.75"
                  />
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 5L11.75 12.25"
                  />
                </svg>
              </Link>
            </div>
          </div>

          <div className="hidden items-center sm:space-x-6 md:flex">
            <a href={`${DASHBOARD_URI}/auth/login`} className={'font-medium text-neutral-800'}>
              Sign in
            </a>
            <motion.a
              whileHover={{scale: 1.1}}
              whileTap={{scale: 0.9}}
              href={`${DASHBOARD_URI}/auth/signup`}
              className={'h-full rounded-lg bg-neutral-800 px-6 py-3 text-sm font-semibold text-white'}
            >
              Create account
            </motion.a>
          </div>

          <div className="-mr-2 flex items-center md:hidden">
            <button
              onClick={() => setMobileOpen(!mobileOpen)}
              type="button"
              className="inline-flex items-center justify-center rounded-sm p-2 text-neutral-400 hover:bg-white hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>

              <svg
                className={`${mobileOpen ? 'hidden' : 'block'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>

              <svg
                className={`${!mobileOpen ? 'hidden' : 'block'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {mobileOpen && (
          <motion.div
            initial={{height: 0, opacity: 0}}
            animate={{height: 'auto', opacity: 1}}
            exit={{height: 0, opacity: 0}}
            transition={{duration: 0.1}}
            className="absolute left-0 z-50 w-full rounded border border-neutral-200 bg-white bg-opacity-70 backdrop-blur-3xl backdrop-filter sm:hidden"
          >
            <motion.div
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              transition={{duration: 0.1}}
              className="space-y-1 pb-3 pt-2"
            >
              <Link
                href={'/made-by-humans'}
                className="block py-2 pl-3 pr-4 text-base font-medium text-neutral-500 transition hover:bg-neutral-100 hover:text-neutral-800"
              >
                By Humans
              </Link>

              <Link
                href={'/pricing'}
                className="block py-2 pl-3 pr-4 text-base font-medium text-neutral-500 transition hover:bg-neutral-100 hover:text-neutral-800"
              >
                Pricing
              </Link>

              <Link
                href={'/blog'}
                className="block py-2 pl-3 pr-4 text-base font-medium text-neutral-500 transition hover:bg-neutral-100 hover:text-neutral-800"
              >
                Blog
              </Link>
              <a
                href={'https://docs.useplunk.com'}
                target={'_blank'}
                rel={'noreferrer'}
                className="block py-2 pl-3 pr-4 text-base font-medium text-neutral-500 transition hover:bg-neutral-100 hover:text-neutral-800"
              >
                Docs
              </a>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
}
